/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */  
}

.App {
  display: flex;
  flex-direction: column;
}

.central-content {
  width: calc(100% - 300px); /* Adjust for the left panel width */
}

/* Header Styling */
.App-header {
  background-color: #282c34;
  color: white;
  padding: 1rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.logo {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.logo:hover {
  text-decoration: underline;
}

.nav-links {
  display: flex;
  gap: 2rem; /* Space between nav items */
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Main Layout */
.main-layout {
  display: flex;
  flex: 1; /* Expand to fill remaining height */
}

.left-panel {
  flex: 0 0 300px; /* Fixed width for the left panel */
  padding: 2rem 1rem;
  height: 80vh; /* Full height to match the viewport */
  width: calc(300px - 2rem); /* Adjust for padding */
  position: sticky; /* Stick to the viewport */
  top: 0; /* Start sticking from the top of the viewport */
}

.headshot {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
}

.left-panel h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.left-panel p {
  margin: 0.5rem 0;
  color: #555;
}

.left-panel-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.left-panel-links a {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
}

.left-panel-links a:hover {
  text-decoration: underline;
}

/* Main Content Styling */
.main-content {
  flex: 1; /* Allow the main content to expand */
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  flex-grow: 1; /* Expand to fill available space */
  padding-bottom: 2rem;
}

.gallery-item {
  background: #f4f4f4;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gallery-item:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.gallery-gif {
  max-width: 100%;
  border-radius: 4px;
  object-fit: contain;
}

.gallery-item h2 {
  font-size: 1.25rem;
  margin: 1rem 0 0.5rem;
}

.tags {
  margin: 10px 0;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  font-size: 0.9rem;
}

.details-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.details-button:hover {
  background-color: #0056b3;
}

/* Footer Section */
footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: auto; /* Push the footer to the bottom */
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
  .main-layout {
    flex-direction: row;
  }

  .main-content {
    flex-direction: column;
    padding: 1rem;
  }

  .left-panel {
    border-right: none;
    position: relative;
  }

  .central-content {
    width: 100%;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}


table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  border-color: inherit !important;
  display: block !important;
  width: max-content !important;
  max-width: 100% !important;
  overflow: auto !important;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit !important;
  border-style: solid !important;
  border-width: 2px !important;
  padding: 0.5rem !important;
}