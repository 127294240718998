.about-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 60px;
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .headshot {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .about-text {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.6;
  }
  